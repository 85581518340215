





































































































































import { promiseHelper } from '@/helper/promise-helper'
import { StakingViewModel } from '@/modules/staking/viewmodels/staking-viewmodel'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ConfirmTransactionDialog extends Vue {
  @Inject({}) vm!: StakingViewModel
  dialog = false
  resolve?: (val: any) => void

  @Prop({ default: '133.57519788918205' }) contribute!: string
  @Prop({ default: 'USDC' }) token!: string
  @Prop({ default: 2 }) step!: number

  timeline = []

  async open() {
    this.dialog = true
    await promiseHelper.delay(100)
    return new Promise((resolve) => (this.resolve = resolve))
  }

  async cancel() {
    this.resolve && this.resolve(null)
    this.dialog = false
    await promiseHelper.delay(100)
  }

  get width() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
        return 320
      case 'md':
        return 431
      case 'lg':
      case 'xl':
        return 719
    }
  }
}
